import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import axios from 'axios'
import * as formStyles from './applyForm.module.scss'

const Spinner = () => (
    <div className={formStyles.ldsSpinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
)

const ApplyForm = (props) => {
    const [uploadFile, setUploadFile] = useState(null)
    const [radioChecked, setRadioChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formSent, setFormSent] = useState(false)
    const [warnings, setWarnings] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
        accept: false,
    })
    const [userEmail, setEmail] = useState(null)
    const [customAlert, setCustomAlert] = useState(null)

    useEffect(() => {
        const isClient = typeof document === 'object'
        if (isClient) {
            const element = document.getElementById('indicate-form-sent')
            if (element && formSent) {
                element.scrollIntoView()
            }
        }
    }, [formSent])

    const onChangingFile = (e) => {
        const validTypes = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
        ]
        if (e.target.files.length !== 0) {
            if (!validTypes.includes(e.target.files[0].type)) {
                setCustomAlert(
                    'File type not supported! Please upload a pdf or doc/docx file!'
                )
                return
            }
            if (e.target.files[0].size > 9999999) {
                setCustomAlert('File too large!')
                return
            }
            setUploadFile(e.target.files[0])
        }
    }

    const onCheckRadio = (e) => {
        setRadioChecked(!radioChecked)
    }

    const validateEmail = (mail) => {
        if (
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
                mail
            )
        ) {
            return true
        }
        return false
    }

    const validatePhoneNumber = (phoneNumber) => {
        if (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(phoneNumber)) {
            return true
        }
        return false
    }

    const postRequest = async (
        path,
        messageBody,
        success,
        failed,
        headers = {
            'Content-Type': 'application/json',
        }
    ) => {
        const response = await axios
            .post(
                `https://www.wr-tools.com/wp-json/contact/v1/${path}`,
                messageBody,
                {
                    headers,
                }
            )
            .then((response) => {
                success(response.data, response.status)
                return response.data
            })
            .catch((error) => {
                console.error(error.response.data.error)
                failed(error)
                return error
            })
        return response
    }

    const sendMailData = (
        jobTitle,
        file,
        name,
        email,
        phone,
        url,
        social,
        message,
        acceptance
    ) => {
        const formData = new FormData()
        formData.append('attachmentFile', file)
        formData.append('contact_name', name)
        formData.append('contact_email', email)
        formData.append('contact_phone', phone)
        formData.append('contact_url', url)
        formData.append('contact_social', social)
        formData.append('contact_acceptance', acceptance)
        formData.append('contact_message', message)
        formData.append('contact_job_title', jobTitle)

        const header = {
            'Content-Type': 'multipart/form-data',
        }
        postRequest(
            'sendmail',
            formData,
            (data, status) => {
                if (data && data.status && data.status !== 200) {
                    setCustomAlert(data.message)
                    setFormSent(true)
                }
                if (
                    data &&
                    data.status &&
                    data.status === 200 &&
                    status === 200
                ) {
                    setFormSent(true)
                }
            },
            () => {
                setCustomAlert('An error occurred! Please try again later!')
                setLoading(true)
            },
            header
        )
    }

    const infividualFieldWarningCheck = (data, fieldName) => {
        const warningStates = { ...warnings }
        if (fieldName === 'contact_name') {
            warningStates.name = false
            if (!data) {
                warningStates.name = true
            }
        }
        if (fieldName === 'contact_email') {
            warningStates.email = false
            if (!data || !validateEmail(data)) {
                warningStates.email = true
            }
        }
        if (fieldName === 'contact_phone') {
            warningStates.phone = false
            if (!data || !validatePhoneNumber(data)) {
                warningStates.phone = true
            }
        }
        if (fieldName === 'contact_message') {
            warningStates.message = false
            if (!data) {
                warningStates.message = true
            }
        }
        setWarnings(warningStates)
    }

    const isFormReady = (name, email, phone, message, acceptance) => {
        let status = true
        const warningStates = {
            name: false,
            email: false,
            phone: false,
            message: false,
            accept: false,
        }
        if (!name) {
            warningStates.name = true
            status = false
        }
        if (!email || !validateEmail(email)) {
            warningStates.email = true
            status = false
        }
        if (!phone || !validatePhoneNumber(phone)) {
            warningStates.phone = true
            status = false
        }
        if (!message) {
            warningStates.message = true
            status = false
        }
        if (!acceptance) {
            warningStates.accept = true
            status = false
        }
        setWarnings(warningStates)
        return { status, warningStates }
    }

    const handleSubmitItemInsert = (e) => {
        e.preventDefault()
        const name = e.target.elements.contact_name.value
        const email = e.target.elements.contact_email.value
        const phone = e.target.elements.contact_phone.value
        const url = e.target.elements.contact_url.value
        const social = e.target.elements.contact_social.value
        const message = e.target.elements.contact_message.value
        const acceptance = radioChecked
        const jobTitle = props.jobTitle

        const formStatus = isFormReady(name, email, phone, message, acceptance)
        if (formStatus.status) {
            setEmail(email)
            setLoading(true)
            sendMailData(
                jobTitle,
                uploadFile,
                name,
                email,
                phone,
                url,
                social,
                message,
                acceptance
            )
        } else {
            if (formStatus.warningStates.name) {
                const element = document.getElementById('name-field-reference')
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                    })
                }
                return
            }
            if (formStatus.warningStates.email) {
                const element = document.getElementById('email-field-reference')
                if (element) {
                    element.scrollIntoView()
                }
                return
            }
            if (formStatus.warningStates.phone) {
                const element = document.getElementById('phone-field-reference')
                if (element) {
                    element.scrollIntoView()
                }
                return
            }
            if (formStatus.warningStates.message) {
                const element = document.getElementById(
                    'message-field-reference'
                )
                if (element) {
                    element.scrollIntoView()
                }
            }
        }
    }
    const fileButtonText = '1 PDF or DOC (max. 10mb)'

    const warningStyle = { borderBottom: '2px solid #e2002a' }

    if (formSent) {
        return (
            <div
                className={formStyles.sentFormContainer}
                id="indicate-form-sent"
            >
                <h2>Thank You!</h2>
                <p>
                    We sent a confirmation of your application to{' '}
                    {userEmail ? userEmail : 'your email address'}. You will
                    hear from us soon!
                </p>
            </div>
        )
    }
    return (
        <div className={formStyles.applyFormContainer}>
            {customAlert ? (
                <div className={formStyles.customAlert}>
                    <div className={formStyles.customAlertBox}>
                        <p>{customAlert}</p>
                        <button
                            onClick={() => {
                                setCustomAlert(null)
                            }}
                            type="button"
                        >
                            OK
                        </button>
                    </div>
                </div>
            ) : null}
            <div className={formStyles.applyTextBox}>
                <p className={formStyles.applyHeader}>Apply now</p>
                <p className={formStyles.applyText}>
                    To apply please include a resume, a portfolio and your view
                    on well-designed digital experiences in the world right now.
                </p>
            </div>
            <div className={formStyles.applyFormBox}>
                <form
                    onSubmit={(e) => {
                        handleSubmitItemInsert(e, uploadFile)
                    }}
                >
                    <div className={formStyles.inputBox}>
                        <div className={formStyles.inputFields}>
                            <div
                                className={formStyles.inputs}
                                id="name-field-reference"
                            >
                                <input
                                    type="text"
                                    id="contact_name"
                                    name="contact_name"
                                    defaultValue=""
                                    placeholder="Name*"
                                    style={warnings.name ? warningStyle : null}
                                    onBlur={(e) => {
                                        infividualFieldWarningCheck(
                                            e.target.value,
                                            e.target.id
                                        )
                                    }}
                                />
                                {warnings.name ? (
                                    <span className={formStyles.placeholder}>
                                        Please tell us your name
                                    </span>
                                ) : null}
                            </div>
                            <div
                                className={formStyles.inputs}
                                id="email-field-reference"
                            >
                                <input
                                    type="text"
                                    id="contact_email"
                                    name="contact_email"
                                    defaultValue=""
                                    placeholder="Email*"
                                    style={warnings.email ? warningStyle : null}
                                    onBlur={(e) => {
                                        infividualFieldWarningCheck(
                                            e.target.value,
                                            e.target.id
                                        )
                                    }}
                                />
                                {warnings.email ? (
                                    <span className={formStyles.placeholder}>
                                        Please enter a valid E-Mail address
                                    </span>
                                ) : null}
                            </div>
                            <div
                                className={formStyles.inputs}
                                id="phone-field-reference"
                            >
                                <input
                                    type="text"
                                    id="contact_phone"
                                    name="contact_phone"
                                    defaultValue=""
                                    placeholder="Phone Number (with country code)*"
                                    style={warnings.phone ? warningStyle : null}
                                    onBlur={(e) => {
                                        infividualFieldWarningCheck(
                                            e.target.value,
                                            e.target.id
                                        )
                                    }}
                                />
                                {warnings.phone ? (
                                    <span className={formStyles.placeholder}>
                                        Please enter a phone number
                                    </span>
                                ) : null}
                            </div>
                            <div className={formStyles.inputs}>
                                <input
                                    type="text"
                                    id="contact_url"
                                    name="contact_url"
                                    defaultValue=""
                                    placeholder="Portfolio URL"
                                />
                            </div>
                            <div className={formStyles.inputs}>
                                <input
                                    type="text"
                                    id="contact_social"
                                    name="contact_social"
                                    defaultValue=""
                                    placeholder="LinkedIn or Xing profile"
                                />
                            </div>
                            <div className={formStyles.fileInputs}>
                                <div className={formStyles.inputZone}>
                                    <input
                                        className={
                                            uploadFile
                                                ? `${formStyles.inputFile} ${formStyles.inputFileSelected}`
                                                : formStyles.inputFile
                                        }
                                        type="file"
                                        id="item_url"
                                        name="item_url"
                                        accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                                        onChange={onChangingFile}
                                    />
                                    <label htmlFor="item_url">
                                        UPLOAD CV/PORTFOLIO
                                    </label>
                                </div>
                                <p className={formStyles.inputDescription}>
                                    {uploadFile ? (
                                        <span>
                                            <span
                                                className={
                                                    formStyles.filenameText
                                                }
                                            >
                                                {uploadFile.name}
                                            </span>
                                            <span
                                                className={
                                                    formStyles.removeButton
                                                }
                                                onClick={() => {
                                                    document.getElementById(
                                                        'item_url'
                                                    ).value = ''
                                                    setUploadFile(null)
                                                }}
                                            >
                                                Remove
                                            </span>
                                        </span>
                                    ) : (
                                        <span>{fileButtonText}</span>
                                    )}
                                </p>
                            </div>
                            <div
                                className={formStyles.inputs}
                                id="message-field-reference"
                            >
                                <textarea
                                    type="text"
                                    id="contact_message"
                                    name="contact_message"
                                    defaultValue=""
                                    placeholder="Say hello and share your views on well-designed digital experiences in the world right now*"
                                    style={
                                        warnings.message ? warningStyle : null
                                    }
                                    onBlur={(e) => {
                                        infividualFieldWarningCheck(
                                            e.target.value,
                                            e.target.id
                                        )
                                    }}
                                />
                                {warnings.message ? (
                                    <span className={formStyles.placeholder}>
                                        Please include a little text here
                                    </span>
                                ) : null}
                            </div>
                            <div className={formStyles.radio}>
                                <p className={formStyles.warn}>
                                    While using our Service, we may ask you to
                                    provide us with certain personally
                                    identifiable information that can be used to
                                    contact or identify you ("Personal Data").
                                </p>
                                <div className={formStyles.tickFlex}>
                                    <input
                                        type="radio"
                                        id="accept_terms"
                                        name="accept_terms"
                                        value="accept"
                                        onClick={onCheckRadio}
                                        onChange={() => {}}
                                        checked={radioChecked}
                                    />
                                    <span
                                        className={formStyles.radioControl}
                                        style={
                                            warnings.accept
                                                ? { borderColor: '#e2002a' }
                                                : { borderColor: '#262626' }
                                        }
                                    />
                                    <div
                                        className={
                                            formStyles.acceptExplanationBox
                                        }
                                    >
                                        <p
                                            className={
                                                formStyles.acceptExplanation
                                            }
                                        >
                                            I agree to{' '}
                                            <Link className={formStyles.privacyLink} to="/privacy">
                                                <b>
                                                    wr&co’s Terms & Conditions
                                                    and the wr&co Privacy Notice
                                                </b>
                                            </Link>
                                            .
                                        </p>
                                        {warnings.accept ? (
                                            <p
                                                className={
                                                    formStyles.placeholder
                                                }
                                            >
                                                Please agree to send your
                                                application
                                            </p>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={formStyles.submitBox}>
                        <button
                            className={`${formStyles.submit} ${formStyles.blue}`}
                            type="submit"
                        >
                            {loading ? <Spinner /> : <span>SUBMIT</span>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ApplyForm
