// extracted by mini-css-extract-plugin
export const postContainer = "jobPost-module--post-container--12hQy";
export const subtitle = "jobPost-module--subtitle--Fl10G";
export const backButton = "jobPost-module--back-button--3m-50";
export const introduction = "jobPost-module--introduction--1gLH7";
export const summaryTitle = "jobPost-module--summary-title--qQ9QQ";
export const block = "jobPost-module--block--1meql";
export const title = "jobPost-module--title--4GcSB";
export const locationIcon = "jobPost-module--location-icon--3onB6";
export const videoContainer = "jobPost-module--video-container--3E2JD";
export const imageContainer = "jobPost-module--image-container--2wDqP";
export const imageBox = "jobPost-module--image-box--2r3Em";
export const appendix = "jobPost-module--appendix--3Ikir";