import React from 'react'
import { graphql } from 'gatsby'
import { default as Job } from '../components/jobPost'

import Layout from '../components/layout'
import SEO from '../components/seo'

export const query = graphql`
    query JobPages($id: String!) {
        wpJob(id: { eq: $id }) {
            title
            job {
                appendix
                bonuses
                experience
                location
                responsibilities
                subheading
                summary
                bonusesTitle
                experienceTitle
                jobSummaryTitle
                responsibilitiesTitle
                useMedia
                heroVideo {
                    mediaItemUrl
                }
            }
        }
        bannerImage: wpJob(id: { eq: $id }) {
            job {
                heroImage {
                    localFile {
                        childImageSharp {
                            id
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                quality: 100
                            )
                        }
                    }
                }
            }
        }
    }
`

const JobPost = (props) => {
    const jobData = props.data.wpJob
    return (
        <Layout noFooter >
            <SEO title={jobData.title} />
            <Job
                title={jobData.title}
                bannerImage={props.data.bannerImage.job.heroImage}
                {...jobData.job}
            />
        </Layout>
    )
}

export default JobPost
