// extracted by mini-css-extract-plugin
export const privacyLink = "applyForm-module--privacy-link--A2kh5";
export const applyFormContainer = "applyForm-module--apply-form-container--24znx";
export const sentFormContainer = "applyForm-module--sent-form-container--1mQWN";
export const customAlert = "applyForm-module--custom-alert--1CWiP";
export const customAlertBox = "applyForm-module--custom-alert-box--xkUi_";
export const applyTextBox = "applyForm-module--apply-text-box--b7_-0";
export const applyHeader = "applyForm-module--apply-header--2baJS";
export const applyText = "applyForm-module--apply-text--2XCUA";
export const applyFormBox = "applyForm-module--apply-form-box--1mrDs";
export const inputBox = "applyForm-module--input-box--1qzJV";
export const fileInputs = "applyForm-module--file-inputs--20A98";
export const inputDescription = "applyForm-module--input-description--GQNLc";
export const filenameText = "applyForm-module--filename-text--1kM2Y";
export const removeButton = "applyForm-module--remove-button--1fFlw";
export const inputZone = "applyForm-module--input-zone--184wC";
export const inputFile = "applyForm-module--input-file--1y6dJ";
export const inputFileSelected = "applyForm-module--input-file-selected--2oZqu";
export const inputFields = "applyForm-module--input-fields--1OkPH";
export const inputs = "applyForm-module--inputs--2cNg9";
export const placeholder = "applyForm-module--placeholder--1nBNf";
export const radio = "applyForm-module--radio--25Pk2";
export const tickFlex = "applyForm-module--tick-flex--_DYvk";
export const warn = "applyForm-module--warn--UrnPH";
export const radioControl = "applyForm-module--radio-control--1bNK7";
export const acceptExplanationBox = "applyForm-module--accept-explanation-box--2j2Bb";
export const acceptExplanation = "applyForm-module--accept-explanation--3NfWI";
export const submitBox = "applyForm-module--submit-box--259HC";
export const submit = "applyForm-module--submit--3C_91";
export const blue = "applyForm-module--blue--2LK2d";
export const red = "applyForm-module--red--191VY";
export const ldsSpinner = "applyForm-module--lds-spinner--3g00O";