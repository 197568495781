import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import ApplyForm from './applyForm'
import { injectSanitiseHtml } from '../utils'

import * as jobStyles from './jobPost.module.scss'

const JobPost = (props) => {
    const renderMedia = () => {
        if (props.useMedia === 'image') {
            return (
                <div className={jobStyles.imageContainer}>
                    <GatsbyImage
                        image={
                            props.bannerImage.localFile.childImageSharp
                                .gatsbyImageData
                        }
                        key={
                            props.bannerImage.localFile.childImageSharp
                                .gatsbyImageData.id
                        }
                        className={jobStyles.imageBox}
                        alt=""
                    />
                </div>
            )
        }
        if (props.useMedia === 'video') {
            return (
                <div className={jobStyles.videoContainer}>
                    <video autoPlay playsInline loop muted>
                        <source
                            src={props.heroVideo.mediaItemUrl}
                            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                        />
                    </video>
                </div>
            )
        }
        return null
    }

    return (
        <div className={jobStyles.postContainer}>
            <Link className={jobStyles.backButton} to="/hiring">
                Back
            </Link>
            <p className={jobStyles.subtitle}>{props.subheading}</p>
            <h1>{props.title}</h1>
            <p>
                <span className={jobStyles.locationIcon}></span>
                {props.location}
            </p>
            {renderMedia()}
            <div className={jobStyles.introduction}>
                <div className={jobStyles.block}>
                    <div className={jobStyles.title}>
                        <h2 className={jobStyles.summaryTitle}>
                            {props.jobSummaryTitle}
                        </h2>
                    </div>
                    {injectSanitiseHtml(props.summary)}
                </div>
            </div>
            <div className={jobStyles.block}>
                <div className={jobStyles.title}>
                    <h3>{props.responsibilitiesTitle}</h3>
                </div>
                {injectSanitiseHtml(props.responsibilities)}
            </div>
            <div className={jobStyles.block}>
                <div className={jobStyles.title}>
                    <h3>{props.experienceTitle}</h3>
                </div>
                {injectSanitiseHtml(props.experience)}
            </div>
            {props.bonuses ? (
                <div className={jobStyles.block}>
                    <div className={jobStyles.title}>
                        <h3>{props.bonusesTitle}</h3>
                    </div>
                    {injectSanitiseHtml(props.bonuses)}
                </div>
            ) : null}
            <div className={jobStyles.block}>
                <div className={jobStyles.title}></div>
                {injectSanitiseHtml(props.appendix, 'div', {
                    marginTop: '5%',
                })}
            </div>
            <ApplyForm jobTitle={props.title} />
        </div>
    )
}

export default JobPost
